import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "semantic-ui-react";

import moment from "moment";
import { beToAd, formatDate } from "react-lib/utils";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

import CardDoctorFeeReportUX from "../APP/CardDoctorFeeReportUX";
import { useIntl } from "react-intl";

import {
  ACTIONS,
  BTN_ACTS,
  CARD_DF_REPORT,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/SetDoctorFeeReport";

// Types
type DoctorFeeReportProps = {
  onEvent: (e: any) => void;
  setProp: SetProp;
  runSequence: RunSequence;
  SetDoctorFeeReportSequence: State["SetDoctorFeeReportSequence"];
  selectedProvider?: Record<string, any>;
} & PickedProps;

// Constants
const CURRENT_DATE = formatDate(moment());
const DATE_FORMAT = "YYYY-MM-DD";

console.log("initial moment locale en week dow 1");
moment.updateLocale("en", { week: { dow: 1 } });

const DoctorFeeReport = (props: DoctorFeeReportProps) => {
  const [tcp, setTcp] = useState("");
  const [tfp, setTfp] = useState("");
  const [tap, setTap] = useState("");
  const [startDate, setStartDate] = useState(CURRENT_DATE);
  const [endDate, setEndDate] = useState(CURRENT_DATE);
  const intl = useIntl();

  // Callback Effect
  const getDoctorFeeReport = useCallback(
    (startDate: string, endDate: string) => {
      props.runSequence({
        sequence: "SetDoctorFeeReport",
        action: ACTIONS.GET_DATA,
        card: CARD_DF_REPORT,
        doctor: props.selectedProvider?.doctor_info?.id,
        end_date: moment(beToAd(endDate)).format(DATE_FORMAT),
        report: false,
        start_date: moment(beToAd(startDate)).format(DATE_FORMAT),
      });
    },
    [props.runSequence, props.selectedProvider?.doctor_info?.id]
  );

  // Effect
  useEffect(() => {
    getDoctorFeeReport(startDate, endDate);

    return () => {
      props.runSequence({
        sequence: "SetDoctorFeeReport",
        clear: true,
      });

      props.setProp(`errorMessage.${CARD_DF_REPORT}`, null);
    };
  }, []);

  useEffect(() => {
    setTcp(formatComma(props.SetDoctorFeeReportSequence?.reportData?.total_compensation_price));
    setTfp(
      formatComma(props.SetDoctorFeeReportSequence?.reportData?.total_final_compensation_price)
    );
    setTap(
      formatComma(props.SetDoctorFeeReportSequence?.reportData?.total_average_compensation_price)
    );
  }, [props.SetDoctorFeeReportSequence?.reportData]);

  // Memo
  const columns = useMemo(
    () => [
      {
        accessor: "code",
        Cell: (props: any) => (
          <span className="code" style={{ display: "flex", justifyContent: "center" }}>
            {props.value}
          </span>
        ),
        getFooterProps: () => ({ style: { border: "0px" } }),
        Header: "CODE",
        width: 150,
      },
      { accessor: "name", Footer: "ผลรวมทั้งหมด", Header: "Name" },
      {
        accessor: "case",
        Cell: (props: any) => (
          <span className="case" style={{ display: "flex", justifyContent: "center" }}>
            {props.value}
          </span>
        ),
        Footer: props.SetDoctorFeeReportSequence?.reportData?.total_case,
        getFooterProps: () => ({
          style: { display: "flex", justifyContent: "center" },
        }),
        Header: "จำนวนคนไข้ (Encounter)",
      },
      {
        accessor: "compensation_price",
        Cell: (props: any) => (
          <span
            className="compensation_price"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {formatComma(props.value)}
          </span>
        ),
        Footer: tcp,
        getFooterProps: () => ({
          style: { display: "flex", justifyContent: "center" },
        }),
        Header: "ยอดเงินรวมค่าตอบแทน (บาท)",
      },
      {
        accessor: "final_compensation_price",
        Cell: (props: any) => (
          <span
            className="final_compensation_price"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {formatComma(props.value)}
          </span>
        ),
        Footer: tfp,
        getFooterProps: () => ({
          style: { display: "flex", justifyContent: "center" },
        }),
        Header: "ยอดเงินรวมค่าตอบแทนหลังหักเข้าคณะ (บาท)",
      },
      {
        accessor: "average_compensation_price",
        Cell: (props: any) => (
          <span
            className="average_compensation_price"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {formatComma(props.value)}
          </span>
        ),
        Footer: tap,
        getFooterProps: () => ({
          style: { display: "flex", justifyContent: "center" },
        }),
        Header: "ยอดเงินรวมค่าตอบแทนเฉลี่ย (บาท)",
      },
    ],
    [props.SetDoctorFeeReportSequence?.reportData, tap, tcp, tfp]
  );

  const formatComma = (value: string): string => {
    if (Number.isNaN(Number.parseFloat(value))) {
      return value;
    }

    const formatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    return formatter.format(Number.parseFloat(value));
  };

  const handleSearch = () => {
    getDoctorFeeReport(startDate, endDate);
  };

  const handleDownload = async () => {
    props.runSequence({
      sequence: "SetDoctorFeeReport",
      action: ACTIONS.EXPORT_REPORT,
      card: CARD_DF_REPORT,
      endDate,
      selectedProvider: props.selectedProvider,
      startDate,
    });
  };

  return (
    <div style={{ height: "90vh" }}>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_DF_REPORT]}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_DF_REPORT}`, null);
        }}
        languageUX={props.languageUX}
      />

      <div style={{ margin: "1rem" }}>
        <Button color="teal" active>
          รายงานภาพรวมค่าตอบแทน
        </Button>
      </div>
      <div style={{ borderBottom: "1px solid grey", margin: "1rem 1rem 0.75rem" }} />

      <CardDoctorFeeReportUX
        doctorFeeColumns={columns}
        doctorFeeData={props.SetDoctorFeeReportSequence?.reportData?.items}
        doctorName={props.selectedProvider?.doctor_info?.name_code}
        endDate={endDate}
        startDate={startDate}
        tableStyle={{ zoom: "95%" }}
        onChangeEndDate={(date: any) => {
          setEndDate(date);
        }}
        onChangeStartDate={(date: any) => {
          setStartDate(date);
        }}
        buttonExportReport={
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"orange"}
            paramKey={BTN_ACTS.EXPORT_REPORT}
            size="medium"
            title="Export Report"
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.EXPORT_REPORT]}
            onClick={handleDownload}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"blue"}
            paramKey={BTN_ACTS.GET_DATA}
            size="medium"
            title={intl.formatMessage({ id: "ค้นหา" })}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.GET_DATA]}
            onClick={handleSearch}
          />
        }
        languageUX={props.languageUX}
      />
    </div>
  );
};

DoctorFeeReport.displayName = "DoctorFeeReport";

export default React.memo(DoctorFeeReport);
